.navbar {
  font-weight: 600;
  border-bottom: 1px solid #dee9f2;
  box-shadow: 0 2px 23px 0 rgba(0, 0, 0, 0.1), 0 2px 49px 0 rgba(0, 0, 0, 0.06);
  .navbar-brand {
    color: $blue ! important;
    text-transform: uppercase;
  }
}
.brand-indent {
  width: 250px;
  min-width: 250px;
  height: 1px;
  float: left;
}