.ortic-form-horizontal {
  .form-group {
    display: flex;
    label:not(.custom-control-label) {
      width: 200px;
      padding: 5px 5px 0 0;
    }
  }
}
.form-group {
  &.has-error {
    .file-upload-contrainer {
      border: 1px solid $danger;
    }
    input, select, textarea {
      border-color: $danger;
    }
    label:not(.file-upload-contrainer) {
      color: $danger;
    }
  }
}


.form-control-percentage {
	max-width: 75px;
}