//Body

$body-bg: #ececee;

$blue: #044072;


  // Links
$link-color: #044072;

// Cards
$card-spacer-x: 0.9375rem;
$card-spacer-y: 0.625rem;
$card-cap-bg: #fbfbfb;
$card-border-color: #e8eced;

// Borders
$border-radius: .125rem;
$border-radius-lg: .2rem;
$border-radius-sm: .15rem;

// Nav Pills
$nav-pills-border-radius: 0;

// Nav Tabs
$nav-tabs-border-color: transparent;
$nav-tabs-border-width: 0;
$nav-tabs-link-active-bg: inherit;
