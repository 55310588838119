.card {
  box-shadow: none;
  background: transparent;
  border: none;

  .card-header {
    text-transform: uppercase;
    font-size: 150%;
    color: $gray-700;
    background-color: transparent;
    border: none;
  }

  .card-body {
    box-shadow: 0 9px 23px rgba(0, 0, 0, 0.09), 0 5px 5px rgba(0, 0, 0, 0.06) !important;
    border-radius: 0.4167rem;
    background: $white;
    /*overflow: hidden; date picker inside card has to be visible*/
    transition: box-shadow 0.7s cubic-bezier(0.25, 0.8, 0.25, 1) !important;

    &:hover {
      box-shadow: 0 9px 23px rgba(0, 0, 0, 0.18), 0 5px 5px rgba(0, 0, 0, 0.12) !important;
    }
    &.no-shadow {
      box-shadow: none ! important;
      padding: 0 ! important;
    }
  }

  .card-footer {
    border-radius: 0;
    background: transparent;
    border: none;
  }
}
