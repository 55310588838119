.nav-tabs {
  .nav-link {
    padding-left: 0;
    font-size: 120%;
    margin-top: 10px;
    text-transform: uppercase;
    color: silver;
    &.active {
      color: black;
    }
  }
}