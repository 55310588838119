.sidebar {
  border-right: 1px solid #e8eced;
  width: 250px;
  height: 100%;
  overflow: auto;

  .sidebar-inner {
    position: fixed;
    top: 57px;
    left: 0;
    bottom: 0;
    overflow: auto;
    width: inherit;
    z-index: 9999;
    box-shadow: 2px 0 10px 0 rgba(0, 0, 0, 0.12), 2px 0 15px 0 rgba(0, 0, 0, 0.09);
  }

  .flex-column {
    height: 100%;
  }

  .nav-item {
    width: 100%;
    .dropdown-item {
      color: white;
      &:hover, &:active, &:focus {
        color: $dark;
      }
    }
  }

  .nav-link {
    position: relative;
    color: white;
    &.dropdown-toggle {
      &::after {
        border-top: 0 ! important;
        border-bottom: 0.3em solid ! important;
        right: 9px;
        position: absolute;
        top: 20px;
      }
      &.collapsed {
        &::after {
          border-bottom: 0 ! important;
          border-top: 0.3em solid ! important;
        }
      }
    }
  }
}
