<template>
  <o-form :title="$t('core.user')" :url="'/api/system/users/' + $route.params.id + '?include=roles'"
          class="ortic-form-horizontal">
    <template slot-scope="props">
      <b-row>
        <b-col>
          <o-input :errorField="'name'" :label="$t('core.name')" v-model="props.item.name"/>
          <o-input :label="$t('core.first_name')" v-model="props.item.first_name"/>
          <o-input :label="$t('core.second_name')" v-model="props.item.second_name"/>
          <o-input :errorField="'email'" :label="$t('core.email')" v-model="props.item.email"/>
          <o-input :errorField="'phone'" :label="$t('core.phone')" v-model="props.item.phone"/>
        </b-col>
        <b-col>
          <o-input :errorField="'password'" :label="$t('core.password')" type="password" v-model="props.item.password"/>
          <o-checkbox :errorField="'is_super_user'" :label="$t('core.is_super_user')" v-model="props.item.is_super_user"/>
          <div class="form-group" :class="{'has-error': 'gln' in $root.errors}">
            <label>{{$t('offer.gln')}}</label>
            <div class="w-100">
              <input v-model="props.item.gln" type="text" class="form-control">
                <div v-if="'gln' in $root.errors" class="w-100 mt-1 text-sm text-danger" v-html="$root.errors['gln'][0]" />
              </div>
          </div>
          <o-checkbox :errorField="'is_hcp'" :label="$t('offer.is_hcp')" v-model="props.item.is_hcp"/>
          <o-form-group :label="$t('core.roles')">
            <div class="w-100">
              <o-typeahead-control class="mb-3" url="/api/system/roles"
                                   v-on:value-selected="item => props.item.roles.push(item)"></o-typeahead-control>
              <ul class="list-group">
                <li class="list-group-item" v-for="(role, roleKey) in props.item.roles" :key="roleKey">
                  {{role.name}}
                  <span @click="props.item.roles.splice(roleKey, 1)" class="float-right">
                                    <fa icon="times"/>
                                </span>
                </li>
              </ul>
            </div>
          </o-form-group>
        </b-col>
      </b-row>
    </template>
  </o-form>
</template>
<style scoped>
 .flex-row-wrap {
   flex-flow: row wrap;
 }
</style>