.v-stepper-root {
  display: flex;
  width: inherit;
  user-select: none;
  box-sizing: border-box;
  justify-content: space-between;

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  a {
    width: 100%;
    text-decoration: none;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .v-step {
    flex: 1;
    opacity: 0.55;
    box-sizing: border-box;
    transition: opacity 0.7s;
  }


  .v-step:hover:not(.is-disabled) {
    opacity: 0.85;
  }

  .v-step *,
  .v-step *::before,
  .v-step *::after {
    box-sizing: inherit;
  }

  .v-step.is-active .label, .v-step.is-visited .label {
    cursor: pointer;
  }

  .v-step.is-active .index, .v-step.is-visited .index {
    background-color: #0460a9;
    color: #ffffff;
  }

  .v-step.is-active {
    opacity: 1;
  }

  .v-step.is-visited .index {
    background-color: #ffffff;
  }

  @media (max-width: 575px) {
    .v-step {
      margin-right: 0.5rem;
    }
  }

  .label {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .index {
    width: 3.5rem;
    height: 3.5rem;
    display: flex;
    flex-shrink: 0;
    font-size: 1.5rem;
    border-radius: 50%;
    margin-right: 0.5rem;
    color: #5389b4;
    align-items: center;
    justify-content: center;
    background-color: #ccc;
    border: 1px solid #f4f4f4;
  }

  .title {
    color: #5389b4;
  }

  .divider {
    width: 100%;
    margin-left: 0.5rem;
    border-bottom: 1px solid #5389b4;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  }
}
