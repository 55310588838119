.table th {
  border-top: none;
}

.cardless-table {
  .card-body {
    box-shadow: none ! important;
    padding: 0 ! important;
    &:hover {
      box-shadow: none ! important;
    }
  }
}