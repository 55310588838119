.hover-control {
  .hover-target {
    visibility: hidden;
  }
  &:hover {
    .hover-target {
      visibility: visible;
    }
  }
}
