.ortic-typeahead-control {
    position: relative;
    ul {
        position: absolute;
        width: 100%;
        z-index: 1000;
        background: white;
        border: $input-border-width solid $input-border-color;
        margin: 0;
        padding: 0;
        li  {
            list-style-type: none;
            padding: $list-group-item-padding-y $list-group-item-padding-x;
            &.active {
                color: $list-group-active-color;
                background-color: $list-group-active-bg;
            }
        }
    }
}
