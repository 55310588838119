.ortic-notifications {
  padding: 10px;
  margin: 15px 15px 0 0;
  font-size: 12px;

  color: #ffffff;
  background: #44A4FC ! important;
  border-left: 5px solid #187FE7;

  &.warn {
    background: #ffb648;
    border-left-color: #f48a06;
  }

  &.error {
    background: #E54D42;
    border-left-color: #B82E24;
  }

  &.success {
    background: #68CD86;
    border-left-color: #42A85F;
  }
}