@import 'variables';
@import '~bootstrap/scss/bootstrap';
@import '~bootstrap-vue/dist/bootstrap-vue.css';
@import 'elements/card';
@import 'elements/buttons';
@import 'elements/sidebar';
@import 'elements/form';
@import 'elements/hover';
@import 'elements/navbar';
@import 'elements/table';
@import 'elements/transitions';
@import 'elements/tabs';
@import 'elements/ortic_value_select_modal';
@import 'elements/datepicker';
@import 'elements/typeahead';
@import 'elements/notifications';
@import 'elements/scrollbar';
@import 'elements/action_toolbar';
@import "elements/font";
@import "elements/wizard";

html, body, #app, .app-layout, .default-layout {
  height: 100%;
}
.default-layout {
  padding: 2rem;
}

#content {
  margin-top: 57px;
}

$activecolor: #221F1F;
$inactivecolor: lighten($activecolor, 50%);
$sandozcolor: #1967ad;

body {
  font-family: 'Noto Sans', sans-serif;
  color: $activecolor;
  background: #ffffff;
}

html, body {
  overflow: auto;
}

h2 {
  margin: 3em 0 0 0;
  font-size: 1.5em;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.navbar-brand {
  z-index: 140;
}

/* -------------------------------------
 * timeline
 * ------------------------------------- */
.timeline {
  font-family: 'PT Sans', sans-serif;
  .container {
	background: #232931;
	width: 1540px;
	height: 500px;
	margin: 0 auto;
	position: relative;
	margin-top: 10%;
	box-shadow: 2px 5px 20px rgba(119, 119, 119, 0.5);
  }

  nav a {
	list-style: none;
	padding: 35px;
	color: #232931;
	font-size: 1.1em;
	display: block;
	transition: all 0.5s ease-in-out;
  }

  .rightbox {
	padding: 0em 0em 0em 0em;
	height: 100%;
  }

  .rb-container {
	font-family: "PT Sans", sans-serif;
	width: 100%;
	margin: auto;
	display: block;
	position: relative;

	ul.rb {
	  margin: 2.5em 0;
	  padding: 0;
	  display: inline-block;

	  li {
		list-style: none;
		margin: auto;
		min-height: 50px;
		border-left: 1px dashed $activecolor;
		padding: 0 0 100px 30px;
		position: relative;

		.timestamp {
		  color: $sandozcolor;
		  position: relative;
		  font-size: 16px;
		}

		.item-title {
		  color: $inactivecolor;

		  img {
			max-width: 70%;
			float: none;
			filter: sepia(100%) hue-rotate(180deg);
		  }
		}

		.fa-bell {
		  cursor: pointer;
		  color: orange;
		  top: 150px;
		  right: 60px;
		  position: absolute;

		  :hover {
			color: lightgreen;
		  }
		}
	  }

	  li:last-child {
		border-left: 0;
	  }

	  li.active {
		.item-title {
		  color: $activecolor;

		  img {
			filter: grayscale(0%);
		  }
		}
	  }

	  li::before {
		position: absolute;
		left: -10px;
		top: 0px;
		content: " ";
		border: 4px solid $activecolor;
		border-radius: 500%;
		background: $sandozcolor;
		height: 20px;
		width: 20px;
		transition: all 500ms ease-in-out;
	  }

	  li:hover::before {
		border-color: $sandozcolor;
		transition: all 1000ms ease-in-out;
	  }
	}
  }

  .docs {
	float: right;
	.btn-text {
		color: $sandozcolor;
	}
	a {
	  font-size: 1.2rem;
	}
  }

  .legal_info {
	float: left;
	color: #a5a5a5;
	margin-top: 8px;
  }

  .ribbon {
	line-height: 0.8em;
	font-size: 2em;
	text-transform: uppercase;
	text-align: center;
	font-weight: bold;
	text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.15);
	letter-spacing: -2px;
	display: block;
	min-width: 7rem;
	// height: 4.2rem;
	background: linear-gradient(to bottom, #999999 0%, #CCCCCC 100%);
	color: white;
	// margin: 1em 0.5em 0;
	float: right;
	padding: 1rem 1rem;
	-webkit-filter: drop-shadow(0 0.5rem 0.3em rgba(0, 0, 0, 0.5));
	transform: translate3d(0, 0, 0);
	top: -100px;
	position: relative;
  }

  .ribbonXX:after {
	content: "";
	width: 100%;
	height: 0;
	border-right: 3rem solid transparent;
	border-left: 3rem solid transparent;
	border-top: 1.5rem solid #CCCCCC;
	position: absolute;
	top: 4.2rem;
	left: 0;
  }

  .ribbon.ribbon--red {
	background: linear-gradient(to bottom, #D3362D 0%, #E57368 100%);
  }

  .ribbon.ribbon--red:after {
	border-top: 1.5rem solid #E57368;
  }

  .ribbon.ribbon--orange {
	background: linear-gradient(to bottom, #E7711B 0%, #F7981D 100%);
  }

  .ribbon.ribbon--orange:after {
	border-top: 1.5rem solid #F7981D;
  }

  .ribbon.ribbon--yellow {
	background: linear-gradient(to bottom, #F1CA3A 0%, #F6EB3B 100%);
  }

  .ribbon.ribbon--yellow:after {
	border-top: 1.5rem solid #F6EB3B;
  }

  .ribbon.ribbon--green {
	background: linear-gradient(to bottom, #5f9654 0%, #65b045 100%);
  }

  .ribbon.ribbon--green:after {
	border-top: 1.5rem solid #65B045;
  }

  .ribbon.ribbon--blue {
	background: linear-gradient(to bottom, #1C91C0 0%, #11A9CC 100%);
  }

  .ribbon.ribbon--blue:after {
	border-top: 1.5rem solid #11A9CC;
  }

  .ribbon.ribbon--purple {
	background: linear-gradient(to bottom, #5C3292 0%, #7E3794 100%);
  }

  .ribbon.ribbon--purple:after {
	border-top: 1.5rem solid #7E3794;
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .timeline {
	.rb-container {
	  ul.rb {
		li {
		  .fa-bell {
			top: 130px;
			right: 60px;
			position: absolute;
		  }

		}
	  }
	}
  }
}

@media (max-width: 768px) {
  .timeline {
	.ribbon {
	  font-size: 1.3em;
	  float: right;
	  position: relative;
	  top: -20px;
	  right: -35px;
	  padding: 0.5rem;
	}

	.rb-container {
	  ul.rb {
		li {
		  .fa-bell {
			top: 75px;
			right: 0;
			position: absolute;
			font-size: 2.5em;
		  }
		}
	  }
	}
  }
}

#content:before {
  content: "";
  position: absolute;
  top: 0;
  left: -1.25rem;
  display: inline-block;
  width: 5rem;
  height: 100%;
  background-repeat: repeat;
  background-size: 5.125rem auto;
}

.reminder-button {
	display: inline-flex;
  	align-items: center; 
}

.footer {
  text-align: center;

  img {
	max-width: 100%;
  }

  .footer-links {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	list-style: none;
	margin-bottom: 0.5rem;
	margin-top: 1.5rem;
	padding: 0;

	.footer-links-item {
	  padding: 0 8px;
	  position: relative;
	}

	.footer-links-item:not(:last-child):after {
	  border-right: 1px solid #003486;
	  content: "";
	  height: 85%;
	  position: absolute;
	  right: 0;
	  top: 50%;
	  transform: translateY(-50%);
	}
  }
}
.vertical-separator {
	width: 1px;
	background-color: #dee2e6;
	margin-left: auto;
	margin-right: auto;
}
.condensed-row {
  td {
    border-top: 0;
    padding: 0.25rem;
  }
}
.form-input-size-small {
	max-width: 100px;
}
.login-page {
	> div {
		max-width: 700px;
	}
}

@media (min-width: 576px) and (max-width: 991px) {
	.modal-dialog {
		max-width: calc(100vw - 50px) ! important;
		margin: 1.75rem auto;
	}
}